exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-appointment-tsx": () => import("./../../../src/pages/appointment.tsx" /* webpackChunkName: "component---src-pages-appointment-tsx" */),
  "component---src-pages-cennik-tsx": () => import("./../../../src/pages/cennik.tsx" /* webpackChunkName: "component---src-pages-cennik-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-oferta-tsx": () => import("./../../../src/pages/oferta.tsx" /* webpackChunkName: "component---src-pages-oferta-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-templates-articles-tsx": () => import("./../../../src/templates/articles.tsx" /* webpackChunkName: "component---src-templates-articles-tsx" */),
  "component---src-templates-employer-detail-page-tsx": () => import("./../../../src/templates/employer-detail-page.tsx" /* webpackChunkName: "component---src-templates-employer-detail-page-tsx" */)
}

