const initialState = {
    activeFaqIndex: -1,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case 'SET_ACTIVE_FAQ':
            return {
                ...state,
                activeFaqIndex: action.activeFaqIndex,
            };
        default:
            return state;
    }
}